import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { rhythm } from '../utils/typography';

const Container = styled.header`
  background-color: #21759b;
  color: #fff;
  padding: ${rhythm(0.2)};
`;

const Title = styled.h1`
  margin: 0;
  text-align: center;
`;

const StyledLink = styled(Link)`
  box-shadow: none;
  color: inherit;
  text-decoration: none;
`;

const Header = ({ title }) => {
  return (
    <Container>
      <Title>
        <StyledLink to={`/`}>{title}</StyledLink>
      </Title>
    </Container>
  );
};

export default Header;
