import React from 'react';
import styled from 'styled-components';

import Header from './header';
import { rhythm } from '../utils/typography';

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(30)};
  padding: ${rhythm(3 / 4)} ${rhythm(1.5)}};
`;

const Footer = styled.footer`
  text-align: center;
`;

class Layout extends React.Component {
  render() {
    const { title, children } = this.props;

    return (
      <div>
        <Header title={title} />
        <Content>
          <main>{children}</main>
          <Footer>© {new Date().getFullYear()} Zen Dexter</Footer>
        </Content>
      </div>
    );
  }
}

export default Layout;
